<template>
    <v-row>
        <v-col cols="12" lg="7" xl="6" class="leftside d-none d-md-flex align-center justify-center position-relative">
            <v-container>
                <div class="pa-10">
                    <v-row justify="center">
                        <v-col cols="8" xl="5">
                            <div>
                                <h2
                                    class="display-1 white--text font-weight-medium"
                                >CPAider</h2>
                                <h6
                                    class="subtitle-1 mt-4 white--text op-5 font-weight-regular"
                                >{{ trans('pages.auth.authText') }}</h6>
                                <v-btn class="mt-4 text-capitalize" x-large outlined color="white" :href="backendUrl">{{ trans('buttons.common.home') }}</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px;">
            </v-container>
        </v-col>
        <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
            <v-container>
                <img src="@/assets/images/cpaider-down.svg" style="position: absolute; top: 0; right: 0; margin-right: 15px; max-width: 70px" class="d-block d-md-none">
                <div class="pa-7 pa-sm-12">
                    <v-row>
                        <v-col cols="12">
                            <lang-switcher></lang-switcher>
                        </v-col>
                        <v-col cols="12" lg="9" xl="6" class="text-center">
                            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">{{ trans('pages.auth.forgot') }}</h2>
                            <h6 class="subtitle-1">
                                {{ trans('pages.register.haveAccount') }}
                                <router-link :to="{name: 'login'}">{{ trans('pages.auth.signIn') }}</router-link>
                            </h6>

                            <v-form v-if="!success">
                                <v-text-field
                                    v-model="email"
                                    :label="trans('pages.fields.email')"
                                    class="mt-4"
                                    :error-messages="errors.email"
                                    required
                                    outlined
                                ></v-text-field>

                                <v-btn
                                    color="info"
                                    block
                                    class="mr-4"
                                    submit
                                    @click="submit"
                                >{{ trans('pages.buttons.resetPassword') }}</v-btn>
                            </v-form>

                            <v-alert
                                v-else
                                outlined
                                type="success"
                                text
                                class="mt-4"
                            >
                                {{ trans('pages.auth.mailSent') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    import LangSwitcher from "@/components/commonComponents/LangSwitcher";
    const axios = window.axios;
    const env = require('@/env.json');
    export default {
        name: "FullForgot",
        components: {LangSwitcher},
        data: () => ({
            password: "",
            show1: false,
            email: "",
            checkbox: false,
            errors: {},
            success: false,
        }),
        computed: {
            backendUrl() {
                return env.backendUrl;
            }
        },
        methods: {

            submit() {
                this.errors = {};
                axios.post(this.route('password.email'), {
                    email: this.email,
                }).then( () => {
                    this.success = true;
                }).catch( errors => {
                    if (errors.response.data && errors.response.data.errors){
                        this.errors = errors.response.data.errors;
                    } else {
                        this.errors.email = this.trans('pages.auth.sendMailError');
                    }
                })
            }
        }
    };
</script>
<style scoped>
    .leftside {
        background: url("../../assets/images/web-web.svg") no-repeat center right;
    }
</style>
